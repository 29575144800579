<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.createAndCopyApiKey') }}
        .description
          a(href="https://www.klaviyo.com/account#api-keys-tab" target="_blank") {{ $t('integrationFlow.integrationModal.general.linkToKeys') }}
    .step
      .circle-holder
        .number 2
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.pasteApiKey') }}
        .description
          .input-holder
            om-input#publicApiKey.mb-auto.w-40.mr-3(
              v-model.trim="settings.publicApiKey"
              type="text"
              :placeholder="$t('frontendRules.klaviyoSegment.addPublicKey')"
              :label="$t('integrationFlow.integrationModal.general.publicApiKey', { type: 'Klaviyo' })"
            )
              template(
                #error
                v-if="validations.publicApiKey.$error && !validations.publicApiKey.required"
              )
                span {{ $t('integrationFlow.integrationModal.general.publicApiKeyError') }}
            om-input#privateKey.w-100(
              v-model.trim="settings.apiKey"
              type="text"
              :placeholder="$t('frontendRules.klaviyoSegment.addPrivateKey')"
              :label="$t('integrationFlow.integrationModal.general.privateApiKey', { type: 'Klaviyo' })"
              :helpText="$t('integrationFlow.klaviyo.fullAccess')"
            )
              template(#error v-if="validations.apiKey.$error && !validations.apiKey.required")
                span {{ $t('integrationFlow.integrationModal.general.privateApiKeyError') }}
    .step
      .circle-holder
        .number 3
      integration-name(:name.sync="settings.name" :validations="validations")
</template>
<script>
  import IntegrationName from '@/components/IntegrationModals/IntegrationName';

  export default {
    components: {
      IntegrationName,
    },

    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },
  };
</script>
