<template lang="pug">
.content-step2
  .content-wrapper-klaviyo(:class="{ 'multi-list-disable': !isMultiListEnabled }")
    om-body-text.mr-2(bt400sm) {{ $t('integrationFlow.integrationModal.general.listHelper', { type: 'Klaviyo' }) }}
    .d-flex(v-if="isMultiListEnabled || isExisting")
      om-body-text.mr-2(bt400sm) {{ $t('integrationFlow.klaviyo.multiListCampaign') }}
      om-switch#multiListSwitch(v-model="multiListToggle")
      om-tooltip(color="#8F97A4" iconSize="1.5rem") {{ $t('integrationFlow.klaviyo.multiListCampaignTooltip') }}
    transition-group(name="fade")
      template(v-if="multiListToggle")
        .multi-list-group(key="multi-list")
          .multi-list-group-item(v-for="(multiListItem, index) in multiListNames")
            om-body-text.mr-2(bt400sm) {{ $t(`integrationFlow.klaviyo.${multiListItem}`) }}
            om-select#listName(
              v-model="multiList[multiListItem]"
              :options="lists"
              :placeholder="$t('integrationFlow.integrationModal.selectAList')"
              @input="handleMultiListChange(multiListItem)"
            )
      template(v-else)
        om-select#listName(
          key="single-list"
          v-model="listId"
          :options="lists"
          :placeholder="$t('integrationFlow.integrationModal.selectAList')"
        )
      #listName.error-msg.mt-3(v-if="hasError")
        span.text-danger(v-html="$t(`integrationFlow.klaviyo.notifications.apiConnectError`)")
</template>
<script>
  import { mapState } from 'vuex';
  import { MULTI_LIST_CAMPAIGN, isFeatureEnabled } from '@/utils/features';
  import { IntegrationService } from '@/services/integrations/integrationService';
  import campaignFieldsMixin from '@/mixins/integration/campaignFields';

  const MULTI_LIST_TYPES = {
    EMAIL_ONLY: 'emailOnly',
    PHONE_ONLY: 'phoneOnly',
    EMAIL_AND_PHONE: 'emailAndPhone',
  };

  export default {
    mixins: [campaignFieldsMixin],
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        listId: null,
        lists: [],
        hasError: false,
        multiListToggle: false,
        multiListNames: [
          MULTI_LIST_TYPES.EMAIL_ONLY,
          MULTI_LIST_TYPES.PHONE_ONLY,
          MULTI_LIST_TYPES.EMAIL_AND_PHONE,
        ],
        multiList: {
          [MULTI_LIST_TYPES.EMAIL_ONLY]: null,
          [MULTI_LIST_TYPES.PHONE_ONLY]: null,
          [MULTI_LIST_TYPES.EMAIL_AND_PHONE]: null,
        },
        campaignFields: [],
        isExisting: false,
      };
    },

    computed: {
      ...mapState(['account']),

      features() {
        return this.account?.features ?? [];
      },

      isMultiListFeatureFlagEnabled() {
        return isFeatureEnabled(this.features, MULTI_LIST_CAMPAIGN);
      },

      hasEmailAndPhoneField() {
        const hasEmail = this.campaignFields.some((field) => field.type === 'email');
        const hasPhoneNumber = this.campaignFields.some((field) => field.type === 'phoneNumber');
        return hasEmail && hasPhoneNumber;
      },

      isMultiListEnabled() {
        return this.isMultiListFeatureFlagEnabled && this.hasEmailAndPhoneField;
      },
    },

    watch: {
      listId(option) {
        if (!option) return;

        this.updateListSetting(option.key);
      },

      multiListToggle(toggleValue) {
        const settings = this.settings;

        if (toggleValue) {
          delete settings.convertedSettings.listId;
          this.setListId(null);
          this.$emit('update:settings', { ...settings });
          return;
        }

        const index = settings.settings.findIndex((item) => item.key === 'multiList');
        settings.settings.splice(index, 1);
        delete settings.convertedSettings.multiList;
        this.setMultiListId(MULTI_LIST_TYPES.EMAIL_ONLY, null);
        this.setMultiListId(MULTI_LIST_TYPES.PHONE_ONLY, null);
        this.setMultiListId(MULTI_LIST_TYPES.EMAIL_AND_PHONE, null);
        this.$emit('update:settings', { ...settings });
      },
    },

    async mounted() {
      if (this.isMultiListFeatureFlagEnabled) {
        this.campaignFields = await this.fetchCampaignFields();
      }
      // IMPORTANT to load data then set model (behavior of om-select)
      const lists = await this.loadLists();

      if (!lists.length) {
        this.updateListSetting(null);
        return;
      }

      this.lists = lists;

      if (this.settings.convertedSettings.hasOwnProperty('multiList')) {
        const multiLists = this.settings.convertedSettings.multiList;
        this.multiListToggle = true;
        this.setMultiListId(MULTI_LIST_TYPES.EMAIL_ONLY, multiLists.emailOnly);
        this.setMultiListId(MULTI_LIST_TYPES.PHONE_ONLY, multiLists.phoneOnly);
        this.setMultiListId(MULTI_LIST_TYPES.EMAIL_AND_PHONE, multiLists.emailAndPhone);
        this.isExisting = true;
      } else {
        const key = this.settings.convertedSettings.listId;
        // om-select selected option only needs "key" from object
        this.setListId(key);
      }
    },

    methods: {
      async loadLists() {
        const integrationService = new IntegrationService(this.$apollo);
        let lists = [];

        try {
          const result = await integrationService.fetchIntegrationData(
            this.settings.type,
            this.settings.id,
          );

          if (result.success) {
            lists = result.lists.map((list) => ({
              key: list.id,
              value: list.name,
            }));
          } else {
            this.errorNotification(result.error);
          }
        } catch (e) {
          this.errorNotification(e.message);
        }

        return lists;
      },

      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
        setTimeout(() => {
          this.$bus.$emit('integration-show-first-step');
        }, 2000);
      },

      handleMultiListChange(type) {
        this.updateMultiListSetting(type, this.multiList[type]);
      },

      updateMultiListSetting(type, value) {
        const settings = this.settings;
        if (!settings.convertedSettings.multiList) {
          settings.convertedSettings.multiList = {};
        }

        settings.convertedSettings.multiList[type] = value.key;
        this.$emit('update:settings', { ...settings });
      },

      updateListSetting(value) {
        const settings = this.settings;
        settings.convertedSettings.listId = value;
        delete settings.convertedSettings?.multiList;
        this.$emit('update:settings', { ...settings });
      },

      setListId(value) {
        this.listId = value ? { key: value } : null;
      },

      setMultiListId(multiListType, value) {
        this.multiList[multiListType] = value ? { key: value } : null;
      },
    },
  };
</script>
<style lang="sass">
  .content-wrapper-klaviyo
    display: flex
    flex-direction: column
    gap: 0.75rem
    &.multi-list-disable
      gap: unset
    .switch
      margin-left: unset
      margin-right: 0.5rem
    .multi-list-group
      display: flex
      flex-direction: column
      gap: 0.75rem
      &-item
        display: flex
        flex-direction: column
        gap: 0.125rem
</style>
